import React, { useState } from "react";

const SearchBar = ({ isOpen }) => {
  const [searchTerm, setSearchTerm] = useState();

  // Array of search suggestions with labels and corresponding queries
  const suggestions = [
    { label: "Top Stories about PLDT", query: "Top Stories about PLDT" },
    { label: "PLDT Investor Relations", query: "PLDT Investor Relations" },
    { label: "PLDT’s Business", query: "PLDT’s Business" },
    {
      label: "Work Opportunities at PLDT",
      query: "Work Opportunities at PLDT",
    },
    {
      label: "PLDT’s Journey of Innovation",
      query: "PLDT’s Journey of Innovation",
    },
    { label: "Annual Reports", query: "Annual Reports" },
  ];

  // Function to quickly search by setting the search term to a given query
  const quickSearch = (query) => {
    setSearchTerm(query); // Update search term state with selected suggestion
  };

  // Function to handle user input changes in the search bar
  const handleInputChange = (e) => {
    setSearchTerm(e.target.value); // Update search term state with user input
  };

  return (
    <div className={`c-search-bar ${!isOpen ? "hidden" : ""}`}>
      <div className="c-search-bar__input-search">
        <div className="form-group">
          <form className="input" action="/search">
            <input
              type="search"
              name="search"
              placeholder="What are you looking for?"
              value={searchTerm}
              onChange={handleInputChange}
            />
            <svg
              className="svg-inline--fa fa-search"
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="search"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              data-fa-i2svg=""
            >
              <path
                fill="currentColor"
                d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
              ></path>
            </svg>
          </form>
        </div>
        <div className="suggestions">
          {suggestions.map((button, index) => (
            <button
              key={index}
              className="c-capsule-btn"
              onClick={() => quickSearch(button.query)}
            >
              {button.label}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
