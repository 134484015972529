import React from "react";
import { Link } from "react-router-dom"; // or your routing solution
import { checkURL } from "../../helpers/helpers.js";

const IconButton = ({
  hideIcon = false,
  title,
  url,
  isPrimary = true,
  isDownload = false,
  downloadTitle,
}) => {
  let filename = "";
  let urlVal = url;

  if (isDownload) {
    filename = `${downloadTitle.toLowerCase().replace(/\s+/g, "-")}.pdf`; // Generate filename for download
  } else {
    urlVal = checkURL(url); // Check URL for non-download case
  }

  const isExternal =
    new URL(url, window.location.href).origin !== window.location.origin; // Check if URL is external

  const content = (
    <>
      {title}
      {isDownload ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
        >
          <path
            d="M21.194 14V20C21.194 20.1492 21.1348 20.2923 21.0293 20.3977C20.9238 20.5032 20.7807 20.5625 20.6315 20.5625H4.13153C3.98235 20.5625 3.83927 20.5032 3.73378 20.3977C3.62829 20.2923 3.56903 20.1492 3.56903 20V14C3.56903 13.8508 3.62829 13.7077 3.73378 13.6023C3.83927 13.4968 3.98235 13.4375 4.13153 13.4375C4.28072 13.4375 4.42379 13.4968 4.52928 13.6023C4.63477 13.7077 4.69403 13.8508 4.69403 14V19.4375H20.069V14C20.069 13.8508 20.1283 13.7077 20.2338 13.6023C20.3393 13.4968 20.4823 13.4375 20.6315 13.4375C20.7807 13.4375 20.9238 13.4968 21.0293 13.6023C21.1348 13.7077 21.194 13.8508 21.194 14ZM11.984 14.3975C12.0895 14.5028 12.2325 14.562 12.3815 14.562C12.5306 14.562 12.6736 14.5028 12.779 14.3975L16.529 10.6475C16.6284 10.5409 16.6825 10.3998 16.6799 10.2541C16.6773 10.1084 16.6183 9.96934 16.5152 9.86628C16.4122 9.76322 16.2731 9.70419 16.1274 9.70162C15.9817 9.69905 15.8407 9.75314 15.734 9.8525L12.944 12.6416V3.5C12.944 3.35082 12.8848 3.20774 12.7793 3.10225C12.6738 2.99676 12.5307 2.9375 12.3815 2.9375C12.2323 2.9375 12.0893 2.99676 11.9838 3.10225C11.8783 3.20774 11.819 3.35082 11.819 3.5V12.6416L9.02903 9.8525C8.9224 9.75314 8.78136 9.69905 8.63564 9.70162C8.48991 9.70419 8.35087 9.76322 8.24781 9.86628C8.14475 9.96934 8.08572 10.1084 8.08315 10.2541C8.08058 10.3998 8.13467 10.5409 8.23403 10.6475L11.984 14.3975Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg
          className={hideIcon ? "hidden" : ""}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M20.6475 12.3975L13.8975 19.1475C13.7909 19.2469 13.6498 19.301 13.5041 19.2984C13.3584 19.2958 13.2193 19.2368 13.1163 19.1337C13.0132 19.0307 12.9542 18.8916 12.9516 18.7459C12.949 18.6002 13.0031 18.4591 13.1025 18.3525L18.8916 12.5625H3.75C3.60082 12.5625 3.45774 12.5032 3.35225 12.3978C3.24676 12.2923 3.1875 12.1492 3.1875 12C3.1875 11.8508 3.24676 11.7077 3.35225 11.6023C3.45774 11.4968 3.60082 11.4375 3.75 11.4375H18.8916L13.1025 5.64751C13.0031 5.54088 12.949 5.39984 12.9516 5.25411C12.9542 5.10839 13.0132 4.96935 13.1163 4.86629C13.2193 4.76323 13.3584 4.7042 13.5041 4.70163C13.6498 4.69905 13.7909 4.75315 13.8975 4.85251L20.6475 11.6025C20.7528 11.708 20.812 11.8509 20.812 12C20.812 12.1491 20.7528 12.292 20.6475 12.3975Z"
            fill={isPrimary ? "#ffffff" : "#951B28"}
          />
        </svg>
      )}
    </>
  );

  return isExternal ? (
    <a
      href={urlVal}
      className={isPrimary ? "c-primary-btn" : "c-primary-btn invi"}
      download={isDownload ? filename : undefined}
    >
      {content}
    </a>
  ) : (
    <Link
      to={urlVal}
      className={isPrimary ? "c-primary-btn" : "c-primary-btn invi"}
    >
      {content}
    </Link>
  );
};

export default IconButton;
