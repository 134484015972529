import React from "react";

const TwitterXIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.52323 12.9054L2.38009 21.0588H5.54442L11.012 14.8014L15.8298 21.0601L22 21.0265L14.1771 10.6819L20.853 3.03482L17.7395 3L12.6968 8.74698L8.38238 3.00938L2 3.00246L9.52323 12.9054ZM18.2631 19.182L16.6663 19.177L5.69567 4.81457H7.41329L18.2631 19.182Z"
        fill="white"
      />
    </svg>
  );
};

export default TwitterXIcon;
