import React from "react";
import { useHistory } from "react-router-dom";

const RedirectBtn = ({ label, url, handleToggleClose }) => {
  const history = useHistory();

  const handleNavigation = () => {
    const isExternal =
      new URL(url, window.location.href).origin !== window.location.origin;

    if (isExternal) {
      window.open(url, "_blank", "noopener noreferrer");
    } else {
      history.push(url); // For internal navigation
    }
    handleToggleClose();
  };

  return <button onClick={handleNavigation}>{label}</button>;
};

export default RedirectBtn;
