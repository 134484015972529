import React, { useState, useEffect, useRef } from "react";
import RedirectBtn from "../buttons/RedirectBtn";

const MenuDropdown = ({
  menuIndex,
  isOpen,
  title,
  toggleDropdown,
  submenu,
}) => {
  const menuRef = useRef(null);

  const handleToggle = () => {
    toggleDropdown(menuIndex); // Toggle the dropdown for the given menu index
  };

  const handleToggleClose = () => {
    toggleDropdown(-1); // Close the dropdown by setting the menu index to -1
  };

  const [openDropdown, setOpenDropdown] = useState(null); // State to manage which dropdown is open

  const toggleSubmenuDropdown = (index) => {
    setOpenDropdown(openDropdown === index ? null : index); // Toggle submenu dropdown open/close based on the index
  };

  useEffect(() => {
    // Function to handle clicks outside the menu to close the dropdown
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        toggleDropdown(false); // Close the dropdown
        setOpenDropdown(null); // Reset the open dropdown state
      }
    };

    // Function to stop propagation inside the <ul> element to prevent triggering outside click handler
    const stopPropagation = (event) => {
      event.stopPropagation();
    };

    // Add event listeners when the component mounts
    document.addEventListener("mousedown", handleClickOutside);
    menuRef.current
      ?.querySelector("ul")
      ?.addEventListener("mousedown", stopPropagation);

    // Cleanup event listeners on component unmount or dependency change
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      menuRef.current
        ?.querySelector("ul")
        ?.removeEventListener("mousedown", stopPropagation);
    };
  }, []); // Empty dependency array ensures this effect runs only on component mount and unmount

  return (
    <div
      ref={menuRef}
      id={`menu-item-${menuIndex}`}
      className={`c-menu-dropdown ${isOpen ? "is-open" : ""}`}
    >
      <span onClick={handleToggle}>{title}</span>
      <svg
        onClick={handleToggle}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M16.6922 7.94229L10.4422 14.1923C10.3842 14.2504 10.3152 14.2965 10.2393 14.328C10.1635 14.3594 10.0821 14.3756 10 14.3756C9.91788 14.3756 9.83655 14.3594 9.76067 14.328C9.6848 14.2965 9.61587 14.2504 9.55782 14.1923L3.30782 7.94229C3.19055 7.82502 3.12466 7.66596 3.12466 7.5001C3.12466 7.33425 3.19055 7.17519 3.30782 7.05792C3.4251 6.94064 3.58416 6.87476 3.75001 6.87476C3.91586 6.87476 4.07492 6.94064 4.1922 7.05792L10 12.8665L15.8078 7.05792C15.8659 6.99985 15.9348 6.95378 16.0107 6.92236C16.0866 6.89093 16.1679 6.87476 16.25 6.87476C16.3321 6.87476 16.4135 6.89093 16.4893 6.92236C16.5652 6.95378 16.6341 6.99985 16.6922 7.05792C16.7503 7.11598 16.7963 7.18492 16.8278 7.26079C16.8592 7.33666 16.8754 7.41798 16.8754 7.5001C16.8754 7.58223 16.8592 7.66354 16.8278 7.73941C16.7963 7.81528 16.7503 7.88422 16.6922 7.94229Z"
          fill="#1B1B1B"
        />
      </svg>
      <ul className={`submenu ${isOpen ? "active" : ""}`}>
        {submenu.map((item, index) => (
          <li key={index}>
            {Array.isArray(item.submenu) ? (
              <div className="c-menu-subnav">
                <div
                  className={`main-nav ${
                    openDropdown === index ? "is-open" : ""
                  }`}
                  onClick={() => toggleSubmenuDropdown(index)}
                >
                  {item.main}
                  <svg
                    className={openDropdown === index ? "rotate-90" : null}
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                  >
                    <path
                      d="M14.0813 10.8313L7.83128 17.0813C7.74242 17.1641 7.62489 17.2092 7.50345 17.207C7.38201 17.2049 7.26615 17.1557 7.18026 17.0698C7.09438 16.9839 7.04518 16.868 7.04304 16.7466C7.0409 16.6252 7.08598 16.5076 7.16878 16.4188L13.0867 10.5L7.16878 4.58128C7.08598 4.49242 7.0409 4.37489 7.04304 4.25345C7.04518 4.13201 7.09438 4.01615 7.18026 3.93026C7.26615 3.84438 7.38201 3.79518 7.50345 3.79304C7.62489 3.7909 7.74242 3.83598 7.83128 3.91878L14.0813 10.1688C14.1691 10.2567 14.2184 10.3758 14.2184 10.5C14.2184 10.6242 14.1691 10.7434 14.0813 10.8313Z"
                      fill="#1B1B1B"
                    />
                  </svg>
                </div>
                <ul
                  className={`subnavs ${
                    openDropdown === index ? "active" : ""
                  }`}
                >
                  {item.submenu.map((itemSubnav, subIndex) => (
                    <li className="subnavs-item" key={subIndex}>
                      <RedirectBtn
                        handleToggleClose={handleToggleClose}
                        label={itemSubnav.main}
                        url={itemSubnav.pathname}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <RedirectBtn
                handleToggleClose={handleToggleClose}
                label={item.main}
                url={item.pathname}
              />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MenuDropdown;
