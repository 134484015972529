import { pldtConfig } from "../config";
// Decodes HTML entities in a string
export const decodeHTMLEntities = (text) => {
  const parser = new DOMParser();
  return parser.parseFromString(text, "text/html").body.textContent;
};

// Transforms field keys to lowercase in an array of objects
export const transformFieldsToLowercase = (data) => {
  return data.map((item) =>
    Object.keys(item).reduce((acc, key) => {
      acc[key.toLowerCase()] = item[key]; // Convert key to lowercase
      return acc;
    }, {})
  );
};

// Formats CTA (Call to Action) by extracting title and URL from HTML string
export const formatCta = (dataArray) => {
  return dataArray.map((obj) => {
    const parser = new DOMParser();
    const ctaDoc = parser.parseFromString(obj.cta, "text/html");

    const linkTitle = ctaDoc.querySelector(".link-title");
    const linkUrl = ctaDoc.querySelector(".link-url a");

    return {
      ...obj,
      cta: {
        title: linkTitle ? linkTitle.textContent : "",
        url: linkUrl ? linkUrl.getAttribute("href") : "",
      },
    };
  });
};

// Transforms a menu from HTML string to an array of objects with label and URL
export const transformMenu = (htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  const links = doc.querySelectorAll("a");

  return Array.from(links).map((link) => ({
    label: link.textContent,
    url: checkURL(link.href), // Check and transform URL
  }));
};

// Checks if URL is external or internal, modifies if needed
export const checkURL = (url) => {
  const isExternal =
    new URL(url, window.location.href).origin !== window.location.origin;

  if (isExternal) return url;

  const currentOrigin = window.location.origin;
  const urlObject = new URL(url, currentOrigin);

  url = urlObject.pathname + urlObject.search + urlObject.hash;

  if (url.startsWith("/drupal")) {
    return url.slice(7); // Remove '/drupal' if present
  }

  return url; // Return original URL if not external or '/drupal'
};

// Fetches a file URL based on the filename query
export const getFile = async (query) => {
  const fetchURL = pldtConfig.apiUrl + `api/v1/get-file?filename=${query}`;

  try {
    const response = await fetch(fetchURL);
    const data = await response.json();
    const filename = data[0].filename;
    const parser = new DOMParser();
    const doc = parser.parseFromString(filename, "text/html");
    const linkElement = doc.querySelector("a");
    return linkElement ? linkElement.getAttribute("href") : "";
  } catch (err) {
    console.error(err.message);
    return "";
  }
};
