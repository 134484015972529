import React from "react";

const FacebookIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M21 12C21 7.02823 16.9718 3 12 3C7.02823 3 3 7.02823 3 12C3 16.492 6.29117 20.2154 10.5938 20.8911V14.6017H8.30746V12H10.5938V10.0171C10.5938 7.76165 11.9365 6.51581 13.9931 6.51581C14.978 6.51581 16.0079 6.69145 16.0079 6.69145V8.90516H14.8727C13.755 8.90516 13.4062 9.59903 13.4062 10.3107V12H15.9023L15.5031 14.6017H13.4062V20.8911C17.7088 20.2154 21 16.492 21 12Z"
        fill="white"
      />
    </svg>
  );
};

export default FacebookIcon;
