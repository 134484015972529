import React from "react";
import { useHistory } from "react-router-dom";

const FooterRedirectBtn = ({ label, url, customClass = "" }) => {
  const history = useHistory();

  const handleNavigation = () => {
    // Check if the URL is external
    const isExternal =
      new URL(url, window.location.href).origin !== window.location.origin;

    isExternal
      ? window.open(url, "_blank", "noopener noreferrer")
      : history.push(url); // Navigate externally or internally
  };

  return (
    <button className={customClass} onClick={handleNavigation}>
      {label}
    </button>
  );
};

export default FooterRedirectBtn;
