import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MenuDropdown from "../inputs/MenuDropdown";
import MobileNav from "./MobileNav";
import SearchBar from "./SearchBar";
import { pldtConfig } from "../../config";

const Header = () => {
  const [openDropdown, setOpenDropdown] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [logo, setLogo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(null);
  const [desktopmenu, setDesktopMenu] = useState([]);

  const staticLogo = "/temp-assets/header-logo.png";

  // Function to process menu URIs
  const processMenuUri = (uri) => {
    // Check if URI starts with "base:"
    if (uri.startsWith("base:")) {
      // If so, replace "base:" with "/" to create a relative path
      return uri.replace("base:", "/");
    }
    // If it's an external link or doesn't start with "base:", return the original URI
    return uri;
  };

  // Function to transform menu items into a specific format
  const transformMenu = (menuItems) => {
    // Map through each item in the menu
    const items = menuItems.map((item) => {
      // Create a new item object with desired properties
      let newItem = {
        main: item.title,
        pathname: processMenuUri(item.uri), // Process URI for internal links
        submenu: item.below
          ? transformMenu(item.below) // Recursively transform submenu if it exists
          : processMenuUri(item.uri), // Otherwise, use the processed URI
      };
      return newItem; // Return the newly created item object
    });

    // Return the transformed list of menu items
    return items;
  };

  useEffect(() => {
    // Set the logo to the static version on component mount
    setStaticLogo();

    // Fetch global header and header menu data concurrently
    Promise.all([getGLobalHeader(), getHeaderMenu()])
      .then(() => setLoading(false)) // Once data is fetched, stop loading
      .catch((err) => {
        console.error(err); // Log any errors that occur during data fetching
      });
  }, []); // Empty dependency array ensures this runs only on initial mount

  // Function to set the logo to a static version
  const setStaticLogo = () => {
    setLogo(staticLogo); // Updates the logo state to the static logo
  };

  const getHeaderMenu = () => {
    // Define the API endpoint for fetching the header menu items
    const fetchURL = pldtConfig.apiUrl + "api/menu_items/header-navigation";

    return fetch(fetchURL)
      .then((response) => response.json()) // Parse the JSON response
      .then((response) => {
        const desktopmenu = transformMenu(response); // Transform the menu data
        setDesktopMenu(desktopmenu); // Update the state with the transformed menu
      })
      .catch((err) => {
        // Handle and optionally log any errors encountered during fetch
        console.error("Failed to fetch menu items", err); // Optional error logging
      });
  };

  const getGLobalHeader = () => {
    // Define the API endpoint for fetching global header data
    const fetchURL = pldtConfig.apiUrl + "api/v1/global-header";

    return fetch(fetchURL)
      .then((response) => response.json()) // Parse the JSON response
      .then((response) => {
        const { logo } = response[0]; // Extract the logo from the response

        setLogo(pldtConfig.appDomain + logo); // Set the logo URL with the app domain
      })
      .catch((err) => {
        // Handle and optionally log any errors encountered during fetch
        console.error("Failed to fetch global header", err); // Optional error logging
      });
  };

  const toggleDropdown = (index) => {
    setActiveIndex(index); // Set the active index for dropdown highlighting
    setOpenDropdown(openDropdown === index ? null : index); // Toggle the dropdown state: open if closed, close if open
  };

  const toggleSearchBar = () => {
    setIsOpen((prevState) => !prevState); // Toggle the search bar visibility by flipping its open state
  };

  const handleBrokenImage = () => {
    setLogo(staticLogo); // Fallback to static logo if the main logo image fails to load
  };

  const isExternalUrl = (url) => {
    // Create a URL object to parse the URL; if no protocol, use current origin as base
    const link = new URL(url, window.location.origin);
    return link.origin !== window.location.origin; // Check if the link's origin differs from the current page's origin
  };

  return (
    <header>
      <nav className="c-header">
        <div className="top-nav">
          <div className="items">
            <div className="ctas">
              <Link to="/contact-us">Contact Us</Link>
              <button onClick={toggleSearchBar}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M21.5306 20.4693L16.8366 15.7762C18.1971 14.1428 18.8755 12.0478 18.7307 9.92691C18.5859 7.80604 17.629 5.82265 16.0591 4.38932C14.4892 2.95599 12.4272 2.18308 10.3019 2.23138C8.17666 2.27968 6.15184 3.14547 4.64867 4.64864C3.1455 6.15181 2.27971 8.17663 2.23141 10.3019C2.18311 12.4271 2.95602 14.4892 4.38935 16.0591C5.82268 17.629 7.80607 18.5859 9.92694 18.7307C12.0478 18.8755 14.1429 18.1971 15.7763 16.8365L20.4694 21.5306C20.5391 21.6003 20.6218 21.6556 20.7128 21.6933C20.8039 21.731 20.9015 21.7504 21 21.7504C21.0985 21.7504 21.1961 21.731 21.2872 21.6933C21.3782 21.6556 21.4609 21.6003 21.5306 21.5306C21.6003 21.4609 21.6556 21.3782 21.6933 21.2871C21.731 21.1961 21.7504 21.0985 21.7504 21C21.7504 20.9014 21.731 20.8038 21.6933 20.7128C21.6556 20.6218 21.6003 20.539 21.5306 20.4693ZM3.75 10.5C3.75 9.16495 4.14588 7.8599 4.88758 6.74987C5.62928 5.63984 6.68349 4.77467 7.91689 4.26378C9.15029 3.75289 10.5075 3.61922 11.8169 3.87967C13.1262 4.14012 14.329 4.78299 15.273 5.727C16.217 6.671 16.8599 7.87374 17.1203 9.18311C17.3808 10.4925 17.2471 11.8497 16.7362 13.0831C16.2253 14.3165 15.3601 15.3707 14.2501 16.1124C13.1401 16.8541 11.835 17.25 10.5 17.25C8.7104 17.248 6.99466 16.5362 5.72922 15.2708C4.46378 14.0053 3.75199 12.2896 3.75 10.5Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className="bottom-nav">
          <div className="items">
            <Link to="/" className="logo">
              <img src={logo} alt="PLDT Logo" onError={handleBrokenImage} />
            </Link>
            <ul className="menus">
              {desktopmenu.map((item, index) => {
                const url = item.pathname || item.submenu;

                return (
                  <li key={index} className="menu-item">
                    {Array.isArray(item.submenu) ? (
                      <MenuDropdown
                        activeIndex={activeIndex}
                        menuIndex={index}
                        isOpen={openDropdown === index}
                        title={item.main}
                        toggleDropdown={toggleDropdown}
                        submenu={item.submenu}
                      />
                    ) : isExternalUrl(url) ? (
                      <a
                        className="link-item"
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.main}
                      </a>
                    ) : (
                      <Link className="link-item" to={url}>
                        {item.main}
                      </Link>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <MobileNav menus={desktopmenu} logo={logo} />
        <SearchBar isOpen={isOpen} />
      </nav>
    </header>
  );
};

export default Header;
