import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DropdownMenuMobile from "../inputs/DropdownMenuMobile";

const MobileNav = ({ menus, logo }) => {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleMobileMenu = () => {
    setOpenMobileMenu((prevState) => !prevState); // Toggle the mobile menu open/close state
  };

  const toggleDropdown = (index) => {
    setOpenDropdown(openDropdown === index ? null : index); // Toggle the dropdown: close if open, open if closed
  };

  useEffect(() => {
    if (openMobileMenu) {
      document.body.style.overflow = "hidden"; // Disable scrolling when mobile menu is open
    } else {
      document.body.style.overflow = ""; // Enable scrolling when mobile menu is closed
    }

    // Cleanup function to re-enable scrolling when the component unmounts or mobile menu toggles
    return () => {
      document.body.style.overflow = "";
    };
  }, [openMobileMenu]); // Dependency on openMobileMenu to re-run effect when it changes

  const closeMobileMenu = () => {
    if (openMobileMenu) toggleMobileMenu(); // Close mobile menu if it’s currently open
  };

  return (
    <div className="c-header-mobile-nav">
      <nav>
        <button onClick={toggleMobileMenu}>
          {/* Hamburger Icon */}
          <svg
            className={openMobileMenu ? "hidden" : ""}
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M28 16C28 16.2652 27.8946 16.5196 27.7071 16.7071C27.5196 16.8946 27.2652 17 27 17H5C4.73478 17 4.48043 16.8946 4.29289 16.7071C4.10536 16.5196 4 16.2652 4 16C4 15.7348 4.10536 15.4804 4.29289 15.2929C4.48043 15.1054 4.73478 15 5 15H27C27.2652 15 27.5196 15.1054 27.7071 15.2929C27.8946 15.4804 28 15.7348 28 16ZM5 9H27C27.2652 9 27.5196 8.89464 27.7071 8.70711C27.8946 8.51957 28 8.26522 28 8C28 7.73478 27.8946 7.48043 27.7071 7.29289C27.5196 7.10536 27.2652 7 27 7H5C4.73478 7 4.48043 7.10536 4.29289 7.29289C4.10536 7.48043 4 7.73478 4 8C4 8.26522 4.10536 8.51957 4.29289 8.70711C4.48043 8.89464 4.73478 9 5 9ZM27 23H5C4.73478 23 4.48043 23.1054 4.29289 23.2929C4.10536 23.4804 4 23.7348 4 24C4 24.2652 4.10536 24.5196 4.29289 24.7071C4.48043 24.8946 4.73478 25 5 25H27C27.2652 25 27.5196 24.8946 27.7071 24.7071C27.8946 24.5196 28 24.2652 28 24C28 23.7348 27.8946 23.4804 27.7071 23.2929C27.5196 23.1054 27.2652 23 27 23Z"
              fill="#1B1B1B"
            />
          </svg>
          {/* Close Icon */}
          <svg
            className={openMobileMenu ? "" : "hidden"}
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M25.7071 24.2926C25.8 24.3855 25.8737 24.4958 25.924 24.6172C25.9743 24.7386 26.0001 24.8687 26.0001 25.0001C26.0001 25.1315 25.9743 25.2616 25.924 25.383C25.8737 25.5044 25.8 25.6147 25.7071 25.7076C25.6142 25.8005 25.5039 25.8742 25.3825 25.9245C25.2611 25.9747 25.131 26.0006 24.9996 26.0006C24.8682 26.0006 24.7381 25.9747 24.6167 25.9245C24.4953 25.8742 24.385 25.8005 24.2921 25.7076L15.9996 17.4138L7.70708 25.7076C7.51944 25.8952 7.26494 26.0006 6.99958 26.0006C6.73422 26.0006 6.47972 25.8952 6.29208 25.7076C6.10444 25.5199 5.99902 25.2654 5.99902 25.0001C5.99902 24.7347 6.10444 24.4802 6.29208 24.2926L14.5858 16.0001L6.29208 7.70757C6.10444 7.51993 5.99902 7.26543 5.99902 7.00007C5.99902 6.7347 6.10444 6.48021 6.29208 6.29257C6.47972 6.10493 6.73422 5.99951 6.99958 5.99951C7.26494 5.99951 7.51944 6.10493 7.70708 6.29257L15.9996 14.5863L24.2921 6.29257C24.4797 6.10493 24.7342 5.99951 24.9996 5.99951C25.2649 5.99951 25.5194 6.10493 25.7071 6.29257C25.8947 6.48021 26.0001 6.7347 26.0001 7.00007C26.0001 7.26543 25.8947 7.51993 25.7071 7.70757L17.4133 16.0001L25.7071 24.2926Z"
              fill="#1B1B1B"
            />
          </svg>
        </button>
        <Link
          onClick={closeMobileMenu}
          to="/"
          className="c-header-mobile-nav__logo"
        >
          <img src={logo} alt="Pldt Mobile Logo" />
        </Link>
        <div style={{ width: "32px", height: "32px" }}></div>
      </nav>
      <div
        className={`c-header-mobile-nav__menus ${
          openMobileMenu ? "active" : ""
        }`}
      >
        <div className="c-mobile-search-bar">
          <form action="/search">
            <input
              type="search"
              name="search"
              placeholder="What are you looking for?"
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
            >
              <path
                d="M17.9419 17.5577L14.0302 13.6468C15.1639 12.2856 15.7293 10.5398 15.6086 8.77238C15.488 7.00499 14.6906 5.35217 13.3823 4.15772C12.074 2.96328 10.3557 2.3192 8.58462 2.35944C6.81357 2.39969 5.12622 3.12118 3.87358 4.37383C2.62094 5.62647 1.89945 7.31382 1.8592 9.08486C1.81895 10.8559 2.46304 12.5743 3.65748 13.8825C4.85192 15.1908 6.50475 15.9882 8.27214 16.1089C10.0395 16.2295 11.7854 15.6642 13.1466 14.5304L17.0575 18.4421C17.1156 18.5002 17.1845 18.5463 17.2604 18.5777C17.3363 18.6091 17.4176 18.6253 17.4997 18.6253C17.5818 18.6253 17.6631 18.6091 17.739 18.5777C17.8149 18.5463 17.8838 18.5002 17.9419 18.4421C17.9999 18.3841 18.046 18.3151 18.0774 18.2392C18.1089 18.1634 18.125 18.0821 18.125 17.9999C18.125 17.9178 18.1089 17.8365 18.0774 17.7606C18.046 17.6848 17.9999 17.6158 17.9419 17.5577ZM3.12469 9.24993C3.12469 8.13741 3.45459 7.04988 4.07267 6.12485C4.69076 5.19982 5.56926 4.47885 6.5971 4.05311C7.62493 3.62737 8.75593 3.51598 9.84707 3.73302C10.9382 3.95006 11.9405 4.48579 12.7272 5.27246C13.5138 6.05913 14.0496 7.06141 14.2666 8.15255C14.4837 9.24369 14.3723 10.3747 13.9465 11.4025C13.5208 12.4304 12.7998 13.3089 11.8748 13.927C10.9497 14.545 9.86221 14.8749 8.74969 14.8749C7.25836 14.8733 5.82858 14.2801 4.77404 13.2256C3.71951 12.1711 3.12634 10.7413 3.12469 9.24993Z"
                fill="#951B28"
              />
            </svg>
          </form>
        </div>
        <ul className="mobile-menu-list">
          {menus.map((item, index) => {
            const isExternal =
              new URL(item.pathname, window.location.href).origin !==
              window.location.origin;
            return (
              <li key={index} className="menu-item">
                {Array.isArray(item.submenu) ? (
                  <DropdownMenuMobile
                    menuIndex={index}
                    isOpen={openDropdown === index}
                    toggleDropdown={toggleDropdown}
                    title={item.main}
                    submenu={item.submenu}
                    onToggleMobileMenu={toggleMobileMenu}
                  />
                ) : isExternal ? (
                  <a className="link-item" href={item.pathname || item.submenu}>
                    {item.main}
                  </a>
                ) : (
                  <Link
                    onClick={toggleMobileMenu}
                    className="link-item"
                    to={item.pathname}
                  >
                    {item.main}
                  </Link>
                )}
              </li>
            );
          })}
          <li className="menu-item">
            <a className="link-item" href="/contact-us">
              Contact Us
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MobileNav;
